import React from "react"
import Flickity from 'react-flickity-component'
import {StaticImage} from "gatsby-plugin-image";

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  watchCSS: true,
  freeScroll: true,
  contain: true,
  adaptiveHeight: true,
  cellAlign: 'left'
}

export default function Hero({appStoreUrl, googlePlayUrl}) {
  return (
    <div className="hero container">
      <div className="content">
        <h2><span>Ganhe cashback</span> em suas compras no supermercado</h2>
        <div className="download">
          <a href={appStoreUrl}><img src="images/app-store.svg" alt="logo da app store"></img></a>
          <a href={googlePlayUrl}><img src="images/play-store.svg" alt="logo da play store"></img></a>
        </div>
      </div>
      <StaticImage
        className="hero-image"
        src="../../static/images/image-main-section_.svg"
        alt="smartphone demonstrando como funciona o aplicativo"
        quality="100"
        objectFit="contain" />
      <div className="hero-footer">
        <h4>Alguns de nossos parceiros:</h4>
        <div className="partners">
          <Flickity
            className={'partners-gallery'}
            options={flickityOptions}
            reloadOnUpdate
            static
          >
            <img className="partners-step" src="images/unilever.svg" alt="logo da Unilever"></img>
            <img className="partners-step" src="images/heineken.svg" alt="logo da Heineken"></img>
            <img className="partners-step" src="images/colgate.svg" alt="logo da Colgate"></img>
            <img className="partners-step" src="images/ambev.svg" alt="logo da Ambev"></img>
            <img className="partners-step" src="images/montana.svg" alt="logo da Montana"></img>
            <img className="partners-step" src="images/bem_fresco.svg" alt="logo da Bem Fresco"></img>
            <img className="partners-step" src="images/pepsico.svg" alt="logo da Pepsico"></img>
            <img className="partners-step" src="images/ype.svg" alt="logo da Ypê"></img>
          </Flickity>
        </div>
      </div>
    </div>
  )
}