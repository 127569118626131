import React from "react"
import { Link } from "gatsby"
import * as styles from "../styles/cases.css"

export default function OurHeader() {
  const openQrCodeModal = () => {
    const modal = document.querySelector("#qrCodeModal") as HTMLDialogElement
    modal.showModal()

    const body = document.querySelector("body")
    body.classList.add("overflow-y")
  }

  return (
    <div className="header">
      <a className="logo" href="http://www.dinerama.com.br">
        <img src="images/logo-name_.svg" alt="dinerama"></img>
      </a>
      <div className="menu-toggle-wrapper">
        <input id="menu-toggle" type="checkbox"/>
        <label className='menu-button-container' htmlFor="menu-toggle">
          <div className='menu-button'></div>
        </label>
        <ul className="mobile-menu-list">
          <li><a href="#">Home</a></li>
          <li><a href="#how-to">Como usar</a></li>
          <li><a href="#cases">Cases</a></li>
          <li><Link to="/partners/">Para empresas</Link></li>
          <li><a href="#faq">Ajuda</a></li>
        </ul>
      </div>

      <ul className="menu-list">
        <li><a href="#">Home</a></li>
        <li><a href="#how-to">Como usar</a></li>
        <li><a href="#cases">Cases</a></li>
        <li><Link to="/partners/">Para empresas</Link></li>
        <li><a href="#faq">Ajuda</a></li>
      </ul>
      <a className="btn earn-cashback-button" onClick={() => openQrCodeModal()}>Ganhe cashback</a>
    </div>
  )
}