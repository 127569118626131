import * as React from "react"
import {useEffect, useRef, useState} from "react"
import Seo from '../seo'
import TagManager from '../tag-manager'
import Footer from '../components/footer'
import HowTo from '../components/how-too'
import Faq from '../components/faq'
import EarnCashback from "../components/earn_cashback"
import WantToBeOurPartner from "../components/be_our_partner"
import Hero from "../components/hero"
import OurHeader from "../components/header"
import asyncAnalytics from "../components/analytics"
import InstallApp from "../components/install-app"
import QRCodeModal from "../components/qrcode_modal"
import SucessHistories from "../components/sucess_histories"

export default function Home(props) {
  const redirectHost = "https://go.dinerama.com.br/"
  const appStoreUrl = "https://apps.apple.com/br/app/dinerama/id1572283434"
  const googlePlayUrl = "https://play.google.com/store/apps/details?id=br.com.dinerama"
  const [url, setUrl] = useState(redirectHost + "?link=instalar")
  const [open, setOpen] = useState(false)
  const [qrCodeModalOpened, setQrCodeModalOpened] = useState(false)
  const modalRef = useRef(null)

  useEffect(() => {
    asyncAnalytics(props)
    const searchParams = new URLSearchParams(props?.location?.search)
    if (searchParams.has("app")) setOpen(true)
    searchParams.set("ga_cookie", document.cookie.split(';').map((c)=> c.split("=")).reduce((a,v) => Object.assign(a,{[v[0].trim()]:v[1]}), {})['_ga_H0509LLPJ3']);
    setUrl(redirectHost + "?" + searchParams.toString())
  }, [props])

  return (
    <div id="root">
      <QRCodeModal
        url={url}
        modalRef={modalRef}
        appStoreUrl={appStoreUrl}
        googlePlayUrl={googlePlayUrl} />
      <InstallApp open={open} url={url} close={() => setOpen(false)} />
      <TagManager />
      <Seo title="Cashback em qualquer supermercado"
           description="Colocamos o poder de escolha nas suas mãos. Com a Dinerama, ganhar dinheiro de volta nas
                  compras do dia-a-dia nunca foi tão simples."/>
      <section className="main">
        <OurHeader />
        <Hero appStoreUrl={appStoreUrl} googlePlayUrl={googlePlayUrl} />
        <HowTo />
        <SucessHistories />
        <WantToBeOurPartner />
        <EarnCashback />
        <Faq />
      </section>
      <Footer/>
    </div>
  )
}
