import React from 'react'
import Flickity from 'react-flickity-component'


const flickityOptions = {
  prevNextButtons: false,
  watchCSS: true,
  freeScroll: true,
  contain: true,
}

export default function HowTo() {
  return (
    <div className="how-to" id="how-to">
      <h3>Veja como é simples:</h3>

      <Flickity
        className={'gallery'}
        options={flickityOptions}
        reloadOnUpdate
        static
      >
        <div className="how-to-step">
          <img src="images/_how-to-step-1.svg" alt="instagram link"></img>

          <div className="content">
            <p className="title">Veja as ofertas</p>
            <p className="description">Navegue entre as categorias e veja as ofertas disponíveis para você ativar.</p>
          </div>
        </div>
        <div className="how-to-step">
          <img src="images/_how-to-step-2.svg" alt="instagram link"></img>

          <div className="content">
            <p className="title">Ative as ofertas</p>
            <p className="description">Ative as ofertas antes de fazer as compras para garantir seu cashback.</p>
          </div>
        </div>
        <div className="how-to-step">
          <img src="images/_how-to-step-3.svg" alt="instagram link"></img>

          <div className="content">
            <p className="title">Escaneie o QR Code</p>
            <p className="description">Nos envie o QR Code da Nota Fiscal da sua compra, e pronto!</p>
          </div>
        </div>
        <div className="how-to-step">
          <img src="images/__how-to-step-4.svg" alt="instagram link"></img>

          <div className="content">
            <p className="title">Receba o cashback</p>
            <p className="description">O seu saldo fica disponível para você fazer o que quiser.</p>
          </div>
        </div>
      </Flickity>
    </div>
  )
}
