import React from "react"

export default function EarnCashback() {
  const openQrCodeModal = () => {
    const modal = document.querySelector("#qrCodeModal") as HTMLDialogElement
    modal.showModal()

    const body = document.querySelector("body")
    body.classList.add("overflow-y")
  }

  return (
    <div className="earn-cashback">
      <div className="content">
        <h4 className="title">Você ganha cashback em compras do dia a dia</h4>
        <p className="description">Nascemos para colocar o poder de decisão na mão do consumidor. Ao invés de se inscrever em milhares de programas de fidelidade diferentes, com a Dinerama você concentra seu cashback em um único lugar.</p>
      </div>
      <a className="" onClick={() => openQrCodeModal()}>Quero ganhar cashback</a>
    </div>
  );
}