import * as React from "react";
import {Link} from "gatsby";
import * as styles from "../styles/sucess-histories"

export default function SucessHistories() {
  return (
  <div className="sucess-histories" id ="cases">
    <div className="intro">
      <h4 className="tittle">Histórias de Sucesso com a Dinerama</h4>
      <p className="description-sucess-histories">Veja casos reais em que a Dinerama acelerou resultados para marcas parceiras</p>
    </div>
    <div className="cases">
      <div className="cardtype row">
        <img className="image-for-card-tittle" src="images/beer.png" alt="" />
          <span>Cervejaria</span>
        </div>
        <h4 className="tittle-cases">Dinerama turbina lançamento de produtos</h4>
        <p className="description-cases">Marca de bebidas lançou uma nova cerveja no mercado utilizando a tecnologia da Dinerama para realizar
          sampling com mensuração de vendas e teste A/B de recorrência.</p>
        <div className="container-item">
          <div className="row box-padding-case">
            <div className="column">
            <span className="porcent">8,4x</span>
            <span>Return on ad spend</span>
            </div>
            <div className="row see-case">
              <Link to={"/cases"}>Ver case
              <img className="arrow-right" src="/images/arrow-right.png" alt=""></img>
              </Link>
              </div>
          </div>
        </div>
    </div>
      <div className="cases">
        <div className="cardtype row">
          <img className="image-for-card-tittle" src="/images/bubbles.png" alt=""></img>
          <span>Lavanderia</span>
        </div>
        <h4 className="tittle-cases">Dinerama entrega ganhos significativos de Lift</h4>
        <p className="description-cases"> Usando as soluções da Dinerama, essa marca de produtos de lavanderia atingiu +94% de
          incrementabilidade nas vendas diárias vs. periodo pré campanha.</p>
        <div className="container-item">
          <div className="row box-padding-case">
            <div className="column">
              <span className="porcent">5,2x</span>
              <span>Return on a spend</span>
            </div>
            <div className="row see-case">
              <Link to={"/cases-laundry"}>Ver case
                <img className="arrow-right" src="/images/arrow-right.png" alt=""></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
  </div>
  );
}